import * as homeAction from "./homeAction";

export const homeFeatureKey = "homeInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  homePageData: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Home Data
    case homeAction.HOME_PAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeAction.HOME_PAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        homePageData: payload,
      };
    case homeAction.HOME_PAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
