import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_ALL_BLOGS_REQUEST = "GET_ALL_BLOGS_REQUEST";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILURE = "GET_ALL_BLOGS_FAILURE";

// Upadte views:
export const UPDATE_BLOG_VIEWS_REQUEST = "UPDATE_BLOG_VIEWS_REQUEST";
export const UPDATE_BLOG_VIEWS_SUCCESS = "UPDATE_BLOG_VIEWS_SUCCESS";
export const UPDATE_BLOG_VIEWS_FAILURE = "UPDATE_BLOG_VIEWS_FAILURE";

export const getAllBlogs = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_BLOGS_REQUEST });
      const url = `${BASE_URL}/user/get/all/blog/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({ type: GET_ALL_BLOGS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_BLOGS_FAILURE, payload: error.message });
    }
  };
};

export const updateBlogViews = (b) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_BLOG_VIEWS_REQUEST });
      const url = `${BASE_URL}/user/update/blog/views/${b?._id}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: UPDATE_BLOG_VIEWS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: UPDATE_BLOG_VIEWS_FAILURE, payload: error.message });
    }
  };
};
