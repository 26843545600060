import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_ALL_VIDIOS_REQUEST = "GET_ALL_VIDIOS_REQUEST";
export const GET_ALL_VIDIOS_SUCCESS = "GET_ALL_VIDIOS_SUCCESS";
export const GET_ALL_VIDIOS_FAILURE = "GET_ALL_VIDIOS_FAILURE";

export const GET_ALL_SUGGESTION_VIDIOS_REQUEST = "GET_ALL_SUGGESTION_VIDIOS_REQUEST";
export const GET_ALL_SUGGESTION_VIDIOS_SUCCESS = "GET_ALL_SUGGESTION_VIDIOS_SUCCESS";
export const GET_ALL_SUGGESTION_VIDIOS_FAILURE = "GET_ALL_SUGGESTION_VIDIOS_FAILURE";

export const GET_AGORE_VIDEOS_TOKEN_REQUEST = "GET_AGORE_VIDEOS_TOKEN_REQUEST";
export const GET_AGORE_VIDEOS_TOKEN_SUCCESS = "GET_AGORE_VIDEOS_TOKEN_SUCCESS";
export const GET_AGORE_VIDEOS_TOKEN_FAILURE = "GET_AGORE_VIDEOS_TOKEN_FAILURE";

// Upadte views:
export const UPDATE_VIDEO_VIEWS_REQUEST = "UPDATE_VIDEO_VIEWS_REQUEST";
export const UPDATE_VIDEO_VIEWS_SUCCESS = "UPDATE_VIDEO_VIEWS_SUCCESS";
export const UPDATE_VIDEO_VIEWS_FAILURE = "UPDATE_VIDEO_VIEWS_FAILURE";

export const getAllVidios = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_VIDIOS_REQUEST });
      const url = `${BASE_URL}/user/get/all/vidios/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({ type: GET_ALL_VIDIOS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_VIDIOS_FAILURE, payload: error.message });
    }
  };
};

export const updateVideoViews = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VIDEO_VIEWS_FAILURE });
      const url = `${BASE_URL}/user/update/video/views/${id}/v1`;
      const response = await axios.put(url,{}, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: UPDATE_VIDEO_VIEWS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: UPDATE_VIDEO_VIEWS_FAILURE, payload: error.message });
    }
  };
};

export const getAllSuggestionVidios = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_SUGGESTION_VIDIOS_REQUEST });
      const url = `${BASE_URL}/user/get/all/vidios/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      console.log(response, "redux");
      dispatch({ type: GET_ALL_SUGGESTION_VIDIOS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_ALL_SUGGESTION_VIDIOS_FAILURE, payload: error.message });
    }
  };
};

export const getAgoraVideoToken = (channel, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_REQUEST });
      const url = `${BASE_URL}/agoraToken/rtc/${channel}/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_FAILURE, payload: error.message });
    }
  };
};

export const endVideoCallToken = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_REQUEST });
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch({ type: GET_AGORE_VIDEOS_TOKEN_FAILURE, payload: error.message });
    }
  };
};
