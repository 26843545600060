import * as blogActions from "./blogAction";
export const blogFeatureKey = "blogInfo";

const initialState = { 
  loading: false,
  errorMsg: "",
  allBlogs: [],
  blogCount: undefined,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // add address
    case blogActions.GET_ALL_BLOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case blogActions.GET_ALL_BLOGS_SUCCESS:
      return {
        ...state,
        allBlogs: payload?.data,
        blogCount: payload?.blogCount[0]?.blogCount,
        loading: false,
      };
    case blogActions.GET_ALL_BLOGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      // update blogs:
      case blogActions.UPDATE_BLOG_VIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case blogActions.UPDATE_BLOG_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case blogActions.UPDATE_BLOG_VIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
