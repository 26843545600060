import * as homeCareActions from "./homeCareAction";
export const homeCareFeatureKey = "homecareInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  bloodTestDropdown: [],
  vaccinationDropdown: [],
  homeCareDropdown: [],
  homeCareAssignId: "",
  homeCareAssignDetails: {},
  homecareAssignsList: [],
  isCancelled:{}
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get Schedule Vets List
    case homeCareActions.GET_BLOODTEST_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_BLOODTEST_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        bloodTestDropdown: payload?.data,
      };
    case homeCareActions.GET_BLOODTEST_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // vaccination dropdown
    case homeCareActions.GET_VACCINATION_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_VACCINATION_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        vaccinationDropdown: payload?.data,
      };
    case homeCareActions.GET_VACCINATION_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Homecare dropdown
    case homeCareActions.GET_HOMECARE_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_HOMECARE_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        homeCareDropdown: payload?.dropdownData,
      };
    case homeCareActions.GET_HOMECARE_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // bloodTest
    case homeCareActions.CREATE_BLOODTEST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.CREATE_BLOODTEST_SUCCESS:
      return {
        ...state,
        loading: false,
        homeCareAssignId: payload?.apointmentId,
      };
    case homeCareActions.CREATE_BLOODTEST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // vaccination
    case homeCareActions.CREATE_VACCINATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.CREATE_VACCINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        homeCareAssignId: payload?.apointmentId,
      };
    case homeCareActions.CREATE_VACCINATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // homecare request
    case homeCareActions.CREATE_HOMECARE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.CREATE_HOMECARE_SUCCESS:
      return {
        ...state,
        loading: false,
        homeCareAssignId: payload?.apointmentId,
      };
    case homeCareActions.CREATE_HOMECARE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // reschedule Homeservice request
    case homeCareActions.RESCHEDULE_HOMECARE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.RESCHEDULE_HOMECARE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case homeCareActions.RESCHEDULE_HOMECARE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get All My Homecare Assigns
    case homeCareActions.GET_ALL_HOMECARE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_ALL_HOMECARE_SUCCESS:
      return {
        ...state,
        loading: false,
        homecareAssignsList: payload,
      };
    case homeCareActions.GET_ALL_HOMECARE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get all my vaccination assigns
    case homeCareActions.GET_ALL_VACCINATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_ALL_VACCINATION_SUCCESS:
      return {
        ...state,
        loading: false,
        homecareAssignsList: payload,
      };
    case homeCareActions.GET_ALL_VACCINATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get All My Bloodtest Assigns
    case homeCareActions.GET_ALL_BLOODTEST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_ALL_BLOODTEST_SUCCESS:
      return {
        ...state,
        loading: false,
        homecareAssignsList: payload,
      };
    case homeCareActions.GET_ALL_BLOODTEST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // bloddTest
    case homeCareActions.GET_HOMECARE_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.GET_HOMECARE_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        homeCareAssignDetails: payload?.data,
      };
    case homeCareActions.GET_HOMECARE_ASSIGN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // cancel homecare assing
    case homeCareActions.CANCEL_HOMECARE_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case homeCareActions.CANCEL_HOMECARE_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isCancelled: payload?.data,
        // homeCareAssignDetails: payload?.data,
      };
    case homeCareActions.CANCEL_HOMECARE_ASSIGN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
