import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const HOME_PAGE_DATA_REQUEST = "HOME_PAGE_DATA_REQUEST";
export const HOME_PAGE_DATA_SUCCESS = "HOME_PAGE_DATA_SUCCESS";
export const HOME_PAGE_DATA_FAILURE = "HOME_PAGE_DATA_FAILURE";

export const getHomeData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HOME_PAGE_DATA_REQUEST });
      const url = `${BASE_URL}/home/get/home/page/info/v1`;
      const response = await axios.post(
        url,
        data,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: HOME_PAGE_DATA_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: HOME_PAGE_DATA_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
