import * as couponCodeAction from "./couponCodeAction";
export const couponFeatureKey = "couponInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  allCoupon: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case couponCodeAction.GET_ALL_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponCodeAction.GET_ALL_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        allCoupon: payload?.data,
      };

    case couponCodeAction.GET_ALL_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
      };

    // Get search coupon
    case couponCodeAction.GET_ALL_SEARCH_COUPON_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponCodeAction.GET_ALL_SEARCH_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        allCoupon: payload?.data,
      };

    case couponCodeAction.GET_ALL_SEARCH_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
