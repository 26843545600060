import * as consultationActions from "./consultationAction";
export const consultationFeatureKey = "consultationInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  vets: [],
  vetsCount: "",
  instantConsultationFees: undefined,
  newConsultationAssign: {},
  singleConsultationDetails: {},
  reasonsData: [],
  consultationList: [],
  isCancelled: {},
  prescriptionDetails: {},
  instantConsultationBookingData: {},
  chatImagePath: "",
  isExistBloodTest:undefined,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get Schedule Vets List
    case consultationActions.GET_SCHEDULE_VETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.GET_SCHEDULE_VETS_SUCCESS:
      return {
        ...state,
        loading: false,
        vets: payload?.data,
        count: payload?.count,
      };
    case consultationActions.GET_SCHEDULE_VETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get Single Asssign
    case consultationActions.GET_CONSULTATION_ASSIGN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.GET_CONSULTATION_ASSIGN_LIST_SUCCESS:
      return {
        ...state,
        // vets: payload?.data,
        consultationList: payload,
        loading: false,
      };
    case consultationActions.GET_CONSULTATION_ASSIGN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    // reschedule vets
    case consultationActions.RESCHEDULE_VETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.RESCHEDULE_VETS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case consultationActions.RESCHEDULE_VETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get Filter Schedule Vets
    case consultationActions.GET_FILTER_SCHEDULE_VETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.GET_FILTER_SCHEDULE_VETS_SUCCESS:
      return {
        ...state,
        loading: false,
        vets: payload?.data,
        count: payload?.count,
      };
    case consultationActions.GET_FILTER_SCHEDULE_VETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // create Assign Consultation

    case consultationActions.CREATE_CONSULTATION_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.CREATE_CONSULTATION_ASSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        // vets: payload?.data,
        newConsultationAssign: payload?.newSchedule,
      };
    case consultationActions.CREATE_CONSULTATION_ASSIGN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get Single Asssign
    case consultationActions.GET_CONSULTATION_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.GET_CONSULTATION_ASSIGN_SUCCESS:
      return {
        ...state,
        singleConsultationDetails: payload?.data,
        prescriptionDetails: payload?.prescriptionDetails,
        procedure:payload?.procedure,
        isExistBloodTest:payload?.isExistBloodTest,
        loading: false,
      };
    case consultationActions.GET_CONSULTATION_ASSIGN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // cancel Schhedule
    case consultationActions.CANCEL_SCHEDULE_VETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.CANCEL_SCHEDULE_VETS_SUCCESS:
      return {
        ...state,
        loading: false,
        isCancelled: payload?.data,
      };
    case consultationActions.CANCEL_SCHEDULE_VETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // cancel Reason Dropdown
    case consultationActions.CANCEL_REASON_DROPDOWN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.CANCEL_REASON_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        reasonsData: payload?.data,
      };
    case consultationActions.CANCEL_REASON_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Instant consultation Call:
    case consultationActions.SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_SUCCESS:
      return {
        ...state,
        loading: false,
        instantConsultationBookingData: payload,
      };
    case consultationActions.SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // Get Instant consultation fees:
    case consultationActions.GET_INSTANT_CONSULTATION_FEES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.GET_INSTANT_CONSULTATION_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        instantConsultationFees: payload,
      };
    case consultationActions.GET_INSTANT_CONSULTATION_FEES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //Get Image sPath For sending in chat:
    case consultationActions.GET_IMAGE_PATH_IN_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.GET_IMAGE_PATH_IN_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        chatImagePath: payload,
      };
    case consultationActions.GET_IMAGE_PATH_IN_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
