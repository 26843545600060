import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Get all coupons
export const GET_ALL_COUPON_REQUEST = "GET_ALL_COUPON_REQUEST";
export const GET_ALL_COUPON_SUCCESS = "GET_ALL_COUPON_SUCCESS";
export const GET_ALL_COUPON_FAILURE = "GET_ALL_COUPON_FAILURE";

// Get all search  coupons
export const GET_ALL_SEARCH_COUPON_REQUEST = "GET_ALL_SEARCH_COUPON_REQUEST";
export const GET_ALL_SEARCH_COUPON_SUCCESS = "GET_ALL_SEARCH_COUPON_SUCCESS";
export const GET_ALL_SEARCH_COUPON_FAILURE = "GET_ALL_SEARCH_COUPON_FAILURE";

export const getAllCoupons = () => {
  return async (dipatch) => {
    try {
      dipatch({ type: GET_ALL_COUPON_REQUEST });
      const url = `${BASE_URL}/coupon/get/all/coupon/card/for/user/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dipatch({ type: GET_ALL_COUPON_SUCCESS, payload: response?.data });
    } catch (error) {
      dipatch({ type: GET_ALL_COUPON_FAILURE });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getSearchCoupons = (data) => {
  return async (dipatch) => {
    try {
      dipatch({ type: GET_ALL_SEARCH_COUPON_REQUEST });
      const url = `${BASE_URL}/coupon/search/coupon/card/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: { searchInput: data },
      });
      dipatch({ type: GET_ALL_SEARCH_COUPON_SUCCESS, payload: response?.data });
    } catch (error) {
      dipatch({ type: GET_ALL_SEARCH_COUPON_FAILURE });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
