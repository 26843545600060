import React, { Suspense, lazy, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import Appointment from "./pages/Appointment";
import { Modal } from "react-bootstrap";
import { onMessageListener } from "./firebase";
import { toast } from "react-toastify";
import * as userActions from "../src/redux/user/userAction";
import { useDispatch } from "react-redux";

const Layout = lazy(() => import("./pages/Layout"));
// const LocationMap=lazy(()=>import("./components/common/LocationMap"))
const Home = lazy(() => import("./pages/home/Home"));
const Login = lazy(() => import("./pages/login/Login"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const MyConsultation = lazy(() => import("./pages/my_consultation/MyConsultation"));
const MyOrders = lazy(() => import("./pages/my_orders/MyOrders"));
const OrderDetails = lazy(() => import("./pages/my_orders/OrderDetails"));
const HVBAppointment = lazy(() => import("./pages/HVBAppointment"));
const PetRegistration = lazy(() => import("./pages/PetRegistration"));
const Consultation = lazy(() => import("./pages/Consultation"));
const DoctorResultList = lazy(() => import("./pages/DoctorResultList"));
const DoctorDetails = lazy(() => import("./pages/DoctorDetails"));
const Payment = lazy(() => import("./pages/Payment"));
// const HomeCare = lazy(() => import("./pages/HomecareVaccineBloodtest"));
const Appointment = lazy(() => import("./pages/Appointment"));
const Pharmacy = lazy(() => import("./pages/Pharmacy"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
const Cart = lazy(() => import("./pages/cart/Cart"));
const HomecareVaccineBloodtest = lazy(() => import("./pages/HomecareVaccineBloodtest"));
const ReportAbuse = lazy(() => import("./pages/ReportAbuse"));
const Videos = lazy(() => import("./pages/Videos"));
const Blogs = lazy(() => import("./pages/blogs/Blogs"));
const BlogDetail = lazy(() => import("./pages/blogs/BlogDetail"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const PaymentSuccessAndFailurePage = lazy(() => import("./pages/PaymentSuccessAndFailurePage"));
const Terms = lazy(() => import("./pages/Terms"));
const Policy = lazy(() => import("./pages/Policy"));
const EHealthCard = lazy(() => import("./pages/EHealthCard"));
const Connect = lazy(() => import("./pages/Connect"));

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

const PublicRoute = ({ redirectPath = "/", children }) => {
  if (localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const App = () => {
  const dispatch = useDispatch();

  onMessageListener()
    .then((payload) => {
      console.log(payload, "notifyPayload");
      toast.info(
        <div>
          <strong>{payload.notification.title}</strong>
          <br />
          {payload.notification.body}
        </div>,
        { autoClose: 5000 } // Set the duration for the toast
      );
      dispatch(userActions.receivedNotification(payload));
    })
    .catch((err) => console.log("failed: ", err));
    
  return (
    <>
      <ScrollToTop>
        <Suspense
          fallback={
            <Modal show={true} className="backdrop_loading" backdrop="static" keyboard={false} centered>
              <Modal.Body className="text-center">
                <Spinner animation="border" variant="light" />
              </Modal.Body>
            </Modal>
          }
        >
          {/* <Modal
            show={false}
            className="backdrop_loading"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Body className="text-center">
              <Spinner animation="border" variant="light" />
            </Modal.Body>
          </Modal> */}
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-consultation"
                element={
                  <ProtectedRoute>
                    <MyConsultation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-orders"
                element={
                  <ProtectedRoute>
                    <MyOrders />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-orders/:id"
                element={
                  <ProtectedRoute>
                    <OrderDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pet-registration"
                element={
                  <ProtectedRoute>
                    <PetRegistration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/goverment-pet-registration"
                element={
                  <ProtectedRoute>
                    <PetRegistration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation"
                element={
                  <ProtectedRoute>
                    <Consultation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/schedule-consultation"
                element={
                  <ProtectedRoute>
                    <DoctorResultList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/schedule-consultation/doctor-details/:id"
                element={
                  <ProtectedRoute>
                    <DoctorDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/instant-consultation/doctor-details/:id/payment"
                element={
                  <ProtectedRoute>
                    <Payment forConsultation forInstantConsultationPayment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/instant-consultation/doctor-details/:id/appointment/join"
                element={
                  <ProtectedRoute>
                    <Appointment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/instant-consultation/doctor-details/:id/appointment/scheduled"
                element={
                  <ProtectedRoute>
                    <Appointment forConsultation forAppointmentScheduled />
                  </ProtectedRoute>
                }
              />
              <Route
                // path="/consultation/schedule-consultation/doctor-details/payment"
                path="/consultation/schedule-consultation/doctor-details/:id/payment"
                element={
                  <ProtectedRoute>
                    <Payment forConsultation forConsultationPayment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/schedule-consultation/doctor-details/:id/appointment/scheduled"
                element={
                  <ProtectedRoute>
                    <Appointment forConsultation forAppointmentScheduled />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/schedule-consultation/doctor-details/:id/appointment/cancelled"
                element={
                  <ProtectedRoute>
                    <Appointment forConsultation forAppointmentCancelled />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/consultation/schedule-consultation/doctor-details/:id/appointment/reschedule"
                element={
                  <ProtectedRoute>
                    <DoctorDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/home-care"
                element={
                  <ProtectedRoute>
                    <HomecareVaccineBloodtest forHomeCare />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/home-care/payment"
                element={
                  <ProtectedRoute>
                    <Payment forHomeCare forHomecarePayment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/home-care/appointment/scheduled/:id"
                element={
                  <ProtectedRoute>
                    <Appointment forHomeCare />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blood-test"
                element={
                  <ProtectedRoute>
                    <HomecareVaccineBloodtest forBloodTest />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blood-test/payment"
                element={
                  <ProtectedRoute>
                    <Payment forBloodTest forHomecarePayment />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blood-test/appointment/scheduled/:id"
                element={
                  <ProtectedRoute>
                    <Appointment forBloodTest />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blood-test/appointment/:id/reschedule"
                element={
                  <ProtectedRoute>
                    <HomecareVaccineBloodtest forBloodTest />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/vaccination"
                element={
                  <ProtectedRoute>
                    <HomecareVaccineBloodtest forVaccination />
                  </ProtectedRoute>
                }
              />
              <Route path="/vaccination/payment" element={<Payment forVaccination forHomecarePayment />} />
              <Route path="/vaccination/appointment/scheduled/:id" element={<Appointment forVaccination />} />
              <Route
                path="/vaccination/appointment/:id/reschedule"
                element={<HomecareVaccineBloodtest forVaccination />}
              />
              <Route
                path="/home-care/appointment/:id/reschedule"
                element={<HomecareVaccineBloodtest forHomeCare forVaccination forBloodTest />}
              />
              <Route
                path="/pharmacy"
                element={
                  <ProtectedRoute>
                    <Pharmacy forAll />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pharmacy/stores"
                element={
                  <ProtectedRoute>
                    <Pharmacy forStoreList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pharmacy/store/:id"
                element={
                  <ProtectedRoute>
                    <Pharmacy forStore />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/product/category"
                element={
                  <ProtectedRoute>
                    <Pharmacy forAll forCategory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pet-category"
                element={
                  <ProtectedRoute>
                    <Pharmacy forAll forPetCategory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/similar/products"
                element={
                  <ProtectedRoute>
                    <Pharmacy forList forSimilarProduct />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/all/products"
                element={
                  <ProtectedRoute>
                    <Pharmacy forList forRandomProducts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer/bought/items"
                element={
                  <ProtectedRoute>
                    <Pharmacy forList forCustomerBuyItems />
                  </ProtectedRoute>
                }
              />
              <Route path="/pharmacy/medicine" element={<Pharmacy forAll />} />
              <Route path="/pharmacy/medicine/concern" element={<Pharmacy forAll forConcern />} />
              <Route path="/pharmacy/medicine/concern/:dynamic" element={<Pharmacy forList />} />
              <Route path="/pharmacy/medicine/concern/product/:id" element={<ProductDetails />} />
              <Route path="/vaccination-appointment" element={<HVBAppointment forVaccination />} />
              <Route path="/homecare-appointment" element={<HVBAppointment forHomeCare />} />
              <Route path="/bloodtest-appointment" element={<HVBAppointment forBloodTest />} />
              {/* <Route path="/home-care" element={<HomeCare />} /> */}
              <Route path="/report-abuse" element={<ReportAbuse />} />
              <Route
                path="/videos"
                element={
                  <ProtectedRoute>
                    <Videos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blogs"
                element={
                  <ProtectedRoute>
                    <Blogs />
                  </ProtectedRoute>
                }
              />
              <Route path="/blog/details" element={<BlogDetail />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/payment/status/:id/v1" element={<PaymentSuccessAndFailurePage isSuccess />} />
              <Route
                path="/cart"
                element={
                  <ProtectedRoute>
                    <Cart />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cart/checkout"
                element={
                  <ProtectedRoute>
                    <Payment forCart forEcommerce />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cart/order/:id"
                element={
                  <ProtectedRoute>
                    <OrderDetails forCart />
                  </ProtectedRoute>
                }
              />
              <Route path="/terms&conditions" element={<Terms />} />
              <Route path="/privacy-policy" element={<Policy />} />
              <Route path="/e-health-card" element={<EHealthCard />} />
              <Route path="*" replace="/home" element={<Layout />} />
            </Route>
            <Route path="/consultation/instant-consultation/doctor-details/connect" element={<Connect />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </>
  );
};

export default App;
